<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  :route="'announcements/' + $route.params.id"
                  purpose="edit"
                  title="Edit Pengumuman"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/masters/announcements/Form.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    async get() {
      this.form = await module.get("announcements/" + this.$route.params.id);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/announcements/list");
      } else {
        this.form["_method"] = "put";
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Pengumuman", route: "/masters/announcements/list" },
      { title: "Edit" },
    ]);

    this.get()
  },
};
</script>